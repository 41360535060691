document.addEventListener('DOMContentLoaded', () => {




    // css du header
    // css section introduction

        // Sélectionnez les éléments h2, h3 et p
        /*
        const h2Element = document.querySelector('.wp-block-heading');
        const h3Element = document.querySelector('.wp-block-heading + .wp-block-heading');
        const  paragraph = document.querySelector('.introduction > p');
        let newDiv = document.createElement('div');
    if(newDiv){
        newDiv.classList.add('introduction__titres');
    
        if(h2Element){
            h2Element.classList.add('introduction__titre-h2');
        }
        if(h3Element){
            h3Element.classList.add('introduction__sous-titre');
        }
        if(paragraph){
            paragraph.classList.add('introduction__paragraphe');
        }
        if(h2Element){
            h2Element.parentNode.insertBefore(newDiv, h2Element);
        }
            newDiv.appendChild(h2Element);
            newDiv.appendChild(h3Element);
        
    }*/

        //css articles 
        var articles = document.querySelectorAll('.articles__article');

        // Parcourez chaque élément et ajoutez la classe souhaitée
        articles.forEach(function(article, index) {
            if (index % 2 === 1) { // L'index est impair
                article.classList.add('articles__article--bg-diff');
            }
            // Ajoutez la classe "border1" à chaque premier article
            if ((index + 1) % 3 === 1) {
                article.classList.add('articles__article--border1');
            }

            // Ajoutez la classe "border3" à chaque troisième article
            if ((index + 1) % 3 === 0) {
                article.classList.add('articles__article--border3');
            }
        });
});



jQuery(document).ready(function() {

    jQuery('.burger, .overlay, .burgerbottom').click(function(){
        jQuery('.burger').toggleClass('clicked');
        //$('.overlay').toggleClass('show');
        jQuery('.menu-mobile').toggleClass('show');
        jQuery('body').toggleClass('overflow');
      });    
   
    jQuery(window).scroll(function () {
        var windowTop = jQuery(window).scrollTop();
        //if(jQuery('#header').length){
            
            if (windowTop > 0) {
            jQuery('body').addClass("sticky");
            } else{
            jQuery('body').removeClass("sticky");
            }
        //}
    
        });	
        /* MENU SMART */
        jQuery(".menu-mobile__list li.menu-item-has-children .sub-menu").hide();
        jQuery(".menu-mobile__list li.menu-item-has-children").click(function(e){
          //e.preventDefault();   
          if (! jQuery(this).hasClass('act')) {
    
            jQuery('.menusmart li.menu-item-has-children').removeClass('act');
    
            jQuery('.menusmart li.menu-item-has-children .sub-menu').slideUp(300);
    
            jQuery(this).addClass("act").find('.sub-menu').slideToggle(300);
    
          }
          else {
            jQuery(this).removeClass("act").find('.sub-menu').slideToggle(300);
          }
    
        });        
});
